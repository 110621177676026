<template>
  <div id="reset-password">
    <div class="container tw-px-4">
      <div class="tw-relative lg:tw-mx-4">
        <div class="tw-bg-white tw-rounded-lg tw-px-4 tw-pb-10 md:tw-mx-20">
          <div class="content tw-py-10 lg:tw-mx-4">
            <h3
              class="tw-text-2xl tw-font-CircularStd-medium tw-font-medium tw-text-secondary"
            >
              Change password
            </h3>
            <p class="tw-text-gray-bg1 tw-text-sm tw-mb-12">
              Secure your account
            </p>
            <form @submit.prevent="submitData">
              <OnboardingPasswordInput
                label="Enter new password"
                :password="payload.password"
                :error="newPasswordError || {}"
                name="newPassword"
                :disabled="loading"
                @set="setNewPassword"
                border="tw-border-[#DDE1E9]"
                @validate="validateMethod"
              />
              <div class="tw-grid tw-grid-cols-2 tw-mt-3 tw-px-2">
                <div class="tw-flex">
                  <img
                    v-if="passwordLength"
                    src="@/assets/img/green-shield.svg"
                    class="tw-mr-1"
                    alt="green shield icon"
                  />
                  <img
                    v-if="!passwordLength"
                    src="@/assets/img/gray-shield.svg"
                    class="tw-mr-1"
                    alt="green shield icon"
                  />
                  <p class="tw-text-gray tw-text-xs">
                    At least 8 characters
                  </p>
                </div>
                <div class="tw-flex">
                  <img
                    v-if="containsNumber"
                    src="@/assets/img/green-shield.svg"
                    class="tw-mr-1"
                    alt="green shield icon"
                  />
                  <img
                    v-if="!containsNumber"
                    src="@/assets/img/gray-shield.svg"
                    class="tw-mr-1"
                    alt="green shield icon"
                  />
                  <p class="tw-text-gray tw-text-xs">
                    At least one number
                  </p>
                </div>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-mt-2 tw-px-2">
                <div class="tw-flex">
                  <img
                    v-if="containsUppercase"
                    src="@/assets/img/green-shield.svg"
                    class="tw-mr-1"
                    alt="green shield icon"
                  />
                  <img
                    v-if="!containsUppercase"
                    src="@/assets/img/gray-shield.svg"
                    class="tw-mr-1"
                    alt="green shield icon"
                  />
                  <p class="tw-text-gray tw-text-xs">
                    At least one uppercase letter
                  </p>
                </div>

                <div class="tw-flex">
                  <img
                    v-if="containsSpecial"
                    src="@/assets/img/green-shield.svg"
                    class="tw-mr-1"
                    alt="green shield icon"
                  />
                  <img
                    v-if="!containsSpecial"
                    src="@/assets/img/gray-shield.svg"
                    class="tw-mr-1"
                    alt="green shield icon"
                  />
                  <p class="tw-text-gray tw-text-xs">
                    At least one special character
                  </p>
                </div>
              </div>
              <OnboardingPasswordInput
                class="tw-mt-6"
                label="Confirm new password"
                :password="payload.confirmPassword"
                :error="confirmPasswordError || {}"
                name="confirmPassword"
                border="tw-border-[#DDE1E9]"
                :disabled="enableConfirmPassword"
                @set="setConfirmPassword"
                @validate="validateMethod"
              />
              <button
                class="tw-w-full tw-rounded tw-text-sm tw-bg-primary tw-text-white md:tw-w-48 tw-p-4 tw-mt-8"
              >
                <span v-show="!loading">Change Password</span>
                <img
                  v-show="loading"
                  class="tw-m-auto"
                  src="@/assets/img/spinner.svg"
                  alt="animated spinner"
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import useValidate from "@vuelidate/core";
  import AccountSidebar from "@/layout/navigation/AccountSidebar.vue";
  import { required, minLength, helpers, sameAs } from "@vuelidate/validators";
  import OnboardingPasswordInput from "@/components/onboarding/OnboardingPasswordInput";
  import { getItem, clearStorage } from "@/utils/storage";

  export default {
    name: "ChangePassword",

    components: {
      AccountSidebar,
      OnboardingPasswordInput,
    },

    beforeRouteEnter(to, from, next) {
      const loggedIn = getItem("org_details");
      const { token } = to.query;
      if (!token) {
        clearStorage();
        return next("/onboarding/signin");
      }
      if (loggedIn === null) {
        return next({ path: "/onboarding/reset-password", query: { token } });
      }
      next();
    },

    data() {
      return {
        v$: useValidate(),
        loading: false,
        payload: {
          password: "",
          confirmPassword: "",
          token: this.$route.query.token,
        },
        enableConfirmPassword: true,
        newPasswordError: {},
        confirmPasswordError: {},
        containsNumber: false,
        containsSpecial: false,
        containsUppercase: false,
        passwordLength: false,
      };
    },

    validations() {
      return {
        payload: {
          password: {
            required,
            minLength: minLength(8),
            containsUppercase: helpers.withMessage(
              "Must contain upper case letter",
              function(value) {
                return /[A-Z]/.test(value);
              }
            ),
            containsNumber: helpers.withMessage("Must contain number", function(
              value
            ) {
              return /[0-9]/.test(value);
            }),
            containsSpecial: helpers.withMessage(
              "Must contain special character [#?!@$%^&*-]",
              function(value) {
                return /[#?!@$%^&*-]/.test(value);
              }
            ),
          },
          confirmPassword: {
            required,
            sameAs: sameAs(this.payload.password),
          },
        },
      };
    },

    methods: {
      ...mapActions("organisationModule", ["resetPassword"]),
      ...mapActions(["showToast"]),

      async submitData() {
        this.v$.$validate();
        const hasValue = Object.keys(this.payload).every(
          (key) => this.payload[key]
        );
        if (this.v$.$errors.length === 0 && hasValue) {
          this.loading = true;
          delete this.payload.confirmPassword;
          const response = await this.resetPassword(this.payload);
          if (response) {
            const { message } = response.data;
            this.showToast({
              description: message,
              display: true,
              type: "success",
            });
            this.$router.push("/dashboard/account");
          }
          this.loading = false;
        }
      },

      setNewPassword(password) {
        this.payload.confirmPassword = "";
        this.payload.password = password;
        if (password.length >= 8) {
          this.enableConfirmPassword = false;
        } else {
          this.enableConfirmPassword = true;
        }
      },

      setConfirmPassword(password) {
        this.payload.confirmPassword = password;
      },

      validateMethod(field) {
        switch (field) {
          case "Enter new password":
            this.v$.$reset();
            this.v$.payload.password.$touch();
            if (this.v$.$errors.length === 0)
              return (this.newPasswordError = {});
            this.newPasswordError = this.v$.$errors[0];
            break;
          case "Confirm new password":
            this.v$.$reset();
            this.v$.payload.confirmPassword.$touch();
            if (this.v$.$errors.length === 0)
              return (this.confirmPasswordError = {});
            this.confirmPasswordError = this.v$.$errors[0];
            break;

          default:
            break;
        }
      },

      checkPassword(password) {
        this.containsNumber = /[0-9]/.test(password);
        this.containsSpecial = /[#?!@$%^&*-]/.test(password);
        this.containsUppercase = /[A-Z]/.test(password);
        this.passwordLength = password.length >= 8;
      },
    },

    watch: {
      newPasswordError(newValue, oldValue) {
        deep: true;
        immediate: true;
        if (newValue !== oldValue) {
          this.checkPassword(this.payload.password);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #reset-password {
    @include fadeIn;

    .container {
      @include fadeIn;

      @media screen and (min-width: 1024px) {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
      }

      .content {
        @media screen and (min-width: 768px) {
          max-width: 460px;
        }
      }
    }
  }
</style>
